//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// import i18n from '@/vueI18n';
import { mapActions, mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { ExporterModel } from '@/modules/exporter/exporter-model';
import { routerAsync } from '@/app-module';

import ExporterErrorModal from '@/modules/exporter/components/exporter-error-modal';
// import ExporterSuccessfullyModal from '@/modules/exporter/components/exporter-successfully-modal';
import ExporterFormScanStep1 from '@/modules/exporter/components/5-exporter-form-scan-step1';
import ExporterFormScanTable from '@/modules/exporter/components/6-exporter-form-scan-table';
import ExporterFormScanPrint from '@/modules/exporter/components/7-exporter-form-scan-print';
import PrintPdfPage from '@/modules/exporter/components/print-pdf-page';
import firebase from 'firebase/app';
import 'firebase/firestore'

const { fields } = ExporterModel;
const formSchema = new FormSchema([
  fields.id,
  fields.itemId,
  fields.itemType,
  fields.serialNumber,
  fields.qrCode,
  fields.warrantyStatus,
  fields.status,
  fields.activationDate,
  fields.expirationDate,
  fields.sellerId,
  fields.exportedBy,
  fields.exportedAt,
  fields.activatedBy,
]);

export default {
  name: 'app-factory-exporter-form-scan',
  props: ['sellerId'],

  components: {
    [ExporterErrorModal.name]: ExporterErrorModal,
    [ExporterFormScanStep1.name]: ExporterFormScanStep1,
    [ExporterFormScanTable.name]: ExporterFormScanTable,
    [ExporterFormScanPrint.name]: ExporterFormScanPrint,
    [PrintPdfPage.name]: PrintPdfPage,
  },

  data() {
    return {
      step: 1,
      model: null,
      exportItemsTable: [],
      error_message: undefined,
      serialNumberInput: undefined,
      errorDialogVisible: false,
      errorModalVisible: false,
      successDialogVisible: false,
      successModalVisible: false,
      rules: formSchema.rules(),
      unsubscribe: undefined,
      successDialog: false
    };
  },

  async creaded() {
    this.unsubscribe = firebase.firestore().collection('items').onSnapshot(async querySnapshot => {
      console.log(`Received query snapshot of size ${querySnapshot.size}`);
      // this.doFetchItems({ filter: { status: 'inFactory' } })
      this.doFetchImpoertedItems({ filter: { status: 'inFactory' } })
    }, err => {
      console.log(`Encountered error: ${err}`);
    });
    // await this.doFetchItemsInBackground({ filter: { status: 'inFactory' } })
    await this.doFetchImpoertedItemsInBackground({ filter: { status: 'inFactory' } })
  },
  beforeDestroy() {
    if (this.unsubscribe) this.unsubscribe()
  },
  async mounted() {
    // await this.doFetchItemsInBackground({ filter: { status: 'inFactory' } })
    await this.doFetchImpoertedItemsInBackground({ filter: { status: 'inFactory' } })
  },

  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      findSellerLoading: 'seller/form/findLoading',
      sellerRecord: 'seller/form/record',
      findBranchLoading: 'branch/form/findLoading',
      branchRecord: 'branch/form/record',

      items: 'exporter/list/rows',
      itemsCount: 'exporter/list/count',
      itemsLoading: 'exporter/list/loading',

      // record: 'exporter/form/record',
      // findLoading: 'exporter/form/findLoading',
      saveLoading: 'exporter/form/saveLoading',
    }),
    fields() {
      return fields;
    },
    printingData() {
      return this.exportItemsTable.map(item => {
        return {
          name: item.itemDetails.name,
          serialNumber: item.serialNumber,
          qrCode: item.qrCode,
        }
      })
    },
    sellerName() {
      return this.vendorRecord ? this.vendorRecord.name : ''
    },
    sellerPhone() {
      return this.vendorRecord ? this.vendorRecord.telephone : ''
    },
    vendorRecord() {
      let record
      if (this.sellerRecord) {
        record = this.sellerRecord
      } else {
        record = this.branchRecord
      }
      return record
    },
    vendorLoading() {
      return this.findSellerLoading || this.findBranchLoading
    },
  },

  methods: {
    ...mapActions({
      doFindSeller: 'seller/form/doFind',
      doFindBranch: 'branch/form/doFind',
      doFetchItemsInBackground: 'exporter/list/doFetchInBackground',
      doFetchItems: 'exporter/list/doFetch',
      doFetchImpoertedItemsInBackground: 'importer/list/doFetchInBackground',
      doFetchImpoertedItems: 'importer/list/doFetch',

      doFind: 'exporter/form/doFind',
      doNew: 'exporter/form/doNew',
      doCreate: `exporter/form/doCreate`,
      doUpdate: `exporter/form/doUpdate`,
      doExportItems: `exporter/form/doExport`,
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    doReset() {
      this.model = formSchema.initialValues(this.record || {});
    },
    // fun() {
    //      console.log('all is well')
    //     // window.scannerDetection();
    //     window.bind('scannerDetectionComplete', (e, data) => {
    //          console.log('complete ', data.string);
    //         // $("#bCode").val(data.string);
    //     })
    // },
    doOpenModal(type) {
      switch (type) {
        case 'error':
          this.errorModalVisible = true
          this.errorDialogVisible = true;
          break;
        case 'success':
          this.successModalVisible = true
          this.successDialogVisible = true;
          break;
      }
    },
    onModalClose() {
      this.errorDialogVisible = false;
      this.successDialogVisible = false;
      this.error_message = undefined
      setTimeout(() => {
        this.errorModalVisible = false
        this.successModalVisible = false
      }, 1000);
    },

    prev() { this.step > 1 ? this.step-- : this.$emit('cancel') },
    next() { this.step++ },

    doAddItemToCart() {
      let inExportTable = this.exportItemsTable.filter(item => item.serialNumber.replace(/^0+/, '') == this.serialNumberInput.replace(/^0+/, ''))
      if (inExportTable.length) {
        this.serialNumberInput = undefined
        return
      }
      let item = this.items.filter(item => item.serialNumber.replace(/^0+/, '') == this.serialNumberInput.replace(/^0+/, ''))
      if (item.length) {
        this.exportItemsTable.push(item[0])
        this.serialNumberInput = undefined
      } else {
        this.error_message = 'entities.exporter.itemNotExist'
        this.doOpenModal('error')
      }
    },
    doRemoveItemFromCart(row) {
      const index = this.exportItemsTable.indexOf(row)
      this.exportItemsTable.splice(index, 1);
    },
    doCancel() {
      routerAsync().push('/factory');
    },
    doTryAgain() {
      if (this.step === 1) {
        this.doCancel()
        this.onModalClose()
      } else {
        this.onModalClose()
        this.$refs.serialNumberInput.focus();
        this.serialNumberInput = undefined
      }
    },
    async doStartScan() {
      await this.doFetchItems({ filter: { status: 'inFactory' } })
      // if (this.sellerId) {
      //   await this.doFindSeller(this.sellerId)
      //   await this.doFindBranch(this.sellerId)
      // }
      // if (this.vendorRecord) {
      return this.next();
      // }
      // this.error_message = 'entities.exporter.sellerNotFound'
      // this.doOpenModal('error')
    },
    async doSubmit() {
      if (this.step == 1) {
        return this.doStartScan();
      }


      if (this.step == 2) {
        // const sellerId = this.sellerId
        const callback = () => {
          this.successDialog = true
          setTimeout(() => {
            routerAsync().push('/factory')
          }, 1500);
        }
        // const updateQuantity = async(collectionName, docId, quantity) => {
        //   const increment = firebase.firestore.FieldValue.increment(quantity);
        //   // Document reference
        //   const docRef = firebase.firestore().collection(collectionName).doc(docId)
        //   // Update quantity 
        //   await docRef.update({
        //     quantity: increment
        //   });
        // }
        let itemsProcessed = 0;
        const values = this.exportItemsTable



        if (navigator.onLine) {
          values.forEach(async (item) => {
            itemsProcessed++;
            await firebase.firestore().collection('items').doc(item.id).update({ status: 'inStock' })
            const increment = firebase.firestore.FieldValue.increment(1);
            const docRef = firebase.firestore().collection('product').doc(item.itemId)
            docRef.update({
              quantity: increment
            });
            // await updateQuantity(item.itemType, item.itemId, 1)
          })
        } else {
          alert(this.i18n('entities.exporter.offline'))
        }

        if (itemsProcessed === values.length) {
          callback();
        }
        console.log(values);
        // await this.doExportItems({
        //   sellerId,
        //   values,
        // });
        // this.next();
      }

      // const { id, ...values } = formSchema.cast(this.model);
      // if (this.isEditing) {

      // } else {
      //   await this.doCreate(values);
      // }
      // // await this.$emit('submit', {
      // //   id,
      // //   values,
      // // });
      // await this.doUpdateSerialAsUsed(this.serialNumbers[0].id)
      // this.numberOfItems > 0 
      //   ? this.doOpenModal('success')
      //   : routerAsync().push('/export')

    },
  },
  watch: {
    serialNumberInput() {
      if (this.serialNumberInput && this.serialNumberInput.length == 12) {
        this.doAddItemToCart()
      }
    }
  },
};
